var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('StatsCard',{staticClass:"mb-4 mb-xl-0",attrs:{"title":"SALES 24 HOURS","type":"gradient-red","sub-title":_vm._f("formatMoney")(_vm.lastDayPurchase.reduce((a, b) => a + b.total, 0)),"icon":"ni ni-active-40"}},[_c('template',{slot:"footer"},[_c('span',{staticClass:"text-nowrap"},[_c('b',{staticStyle:{"color":"yellow"}},[_vm._v("Chip: ")]),_vm._v(" $ "+_vm._s(_vm._f("formatMoney")(_vm.lastDayPurchase.reduce((a, b) => a + b.chipTotal, 0)))+" ")]),_c('span',{staticClass:"text-nowrap"},[_c('b',{staticStyle:{"color":"yellow"}},[_vm._v(" Gold: ")]),_vm._v(" $ "+_vm._s(_vm._f("formatMoney")(_vm.lastDayPurchase.reduce((a, b) => a + b.goldTotal, 0)))+" ")]),_c('span',{staticClass:"text-nowrap"},[_c('b',{staticStyle:{"color":"yellow"}},[_vm._v(" Multi: ")]),_vm._v(" $ "+_vm._s(_vm._f("formatMoney")(_vm.lastDayPurchase.reduce((a, b) => a + b.multiTotal, 0)))+" ")]),_c('span',{staticClass:"text-nowrap"},[_c('b',{staticStyle:{"color":"yellow"}},[_vm._v(" Noel: ")]),_vm._v(" $ "+_vm._s(_vm._f("formatMoney")(_vm.lastDayPurchase.reduce((a, b) => a + b.noelTotal, 0)))+" ")]),_c('br'),_c('span',{staticClass:"text-nowrap"},[_c('b',{staticStyle:{"color":"yellow"}},[_vm._v("Total Order: ")]),_vm._v(" "+_vm._s(_vm.lastDayPurchase.reduce((a, b) => a + b.totalOrder, 0))+" ")]),_c('span',{staticClass:"text-nowrap"},[_c('b',{staticStyle:{"color":"yellow"}},[_vm._v(" Total Uniq Users: ")]),_vm._v(" "+_vm._s(_vm.lastDayPurchase.reduce((a, b) => a + b.uniqueUserCount, 0))+" ")]),_c('br'),_vm._l((_vm.lastDayPurchase),function(item,index){return _c('span',{key:index,staticClass:"text-nowrap"},[_c('b',{staticStyle:{"color":"yellow"}},[_vm._v(" "+_vm._s(item.name)+":")]),_vm._v(" "+_vm._s(item.total)+" ")])}),_c('br'),_c('span',{class:_vm.percentageCalc(_vm.lastDayPurchaseCompare, _vm.lastDayPurchase) > 0
                ? 'text-success mr-2'
                : 'text-danger mr-2'},[_vm._v(_vm._s(_vm.percentageCalc(_vm.lastDayPurchaseCompare, _vm.lastDayPurchase))+"%")]),_c('span',{staticClass:"text-nowrap"},[_vm._v("Since last day $ ("+_vm._s(_vm._f("formatMoney")(_vm.lastDayPurchaseCompare.reduce((a, b) => a + b.total, 0)))+")")])],2)],2)],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('StatsCard',{staticClass:"mb-4 mb-xl-0",attrs:{"title":"SALES 7 DAYS","type":"gradient-red","sub-title":_vm._f("formatMoney")(_vm.lastWeekPurchase.reduce((a, b) => a + b.total, 0)),"icon":"ni ni-active-40"}},[_c('template',{slot:"footer"},[_c('span',{staticClass:"text-nowrap"},[_c('b',{staticStyle:{"color":"yellow"}},[_vm._v("Chip: ")]),_vm._v(" $ "+_vm._s(_vm._f("formatMoney")(_vm.lastWeekPurchase.reduce((a, b) => a + b.chipTotal, 0)))+" ")]),_c('span',{staticClass:"text-nowrap"},[_c('b',{staticStyle:{"color":"yellow"}},[_vm._v(" Gold: ")]),_vm._v(" $ "+_vm._s(_vm._f("formatMoney")(_vm.lastWeekPurchase.reduce((a, b) => a + b.goldTotal, 0)))+" ")]),_c('span',{staticClass:"text-nowrap"},[_c('b',{staticStyle:{"color":"yellow"}},[_vm._v(" Multi: ")]),_vm._v(" $ "+_vm._s(_vm._f("formatMoney")(_vm.lastWeekPurchase.reduce((a, b) => a + b.multiTotal, 0)))+" ")]),_c('span',{staticClass:"text-nowrap"},[_c('b',{staticStyle:{"color":"yellow"}},[_vm._v(" Noel: ")]),_vm._v(" $ "+_vm._s(_vm._f("formatMoney")(_vm.lastWeekPurchase.reduce((a, b) => a + b.noelTotal, 0)))+" ")]),_c('br'),_c('span',{staticClass:"text-nowrap"},[_c('b',{staticStyle:{"color":"yellow"}},[_vm._v("Total Order: ")]),_vm._v(" "+_vm._s(_vm.lastWeekPurchase.reduce((a, b) => a + b.totalOrder, 0))+" ")]),_c('span',{staticClass:"text-nowrap"},[_c('b',{staticStyle:{"color":"yellow"}},[_vm._v(" Total Uniq Users: ")]),_vm._v(" "+_vm._s(_vm.lastWeekPurchase.reduce((a, b) => a + b.uniqueUserCount, 0))+" ")]),_c('br'),_vm._l((_vm.lastWeekPurchase.filter(
              (m) => m.total > 0
            )),function(item,index){return _c('span',{key:index,staticClass:"text-nowrap"},[_c('b',{staticStyle:{"color":"yellow"}},[_vm._v(" "+_vm._s(item.name)+":")]),_vm._v(" "+_vm._s(item.total.toFixed(2))+" ")])}),_c('br'),_c('span',{class:_vm.percentageCalc(_vm.lastWeekPurchaseCompare, _vm.lastWeekPurchase) > 0
                ? 'text-success mr-2'
                : 'text-danger mr-2'},[_vm._v(_vm._s(_vm.percentageCalc(_vm.lastWeekPurchaseCompare, _vm.lastWeekPurchase))+"%")]),_c('span',{staticClass:"text-nowrap"},[_vm._v("Since last week $ ("+_vm._s(_vm._f("formatMoney")(_vm.lastWeekPurchaseCompare.reduce((a, b) => a + b.total, 0)))+")")])],2)],2)],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('StatsCard',{staticClass:"mb-4 mb-xl-0",attrs:{"title":"SALES 15 DAYS","type":"gradient-red","sub-title":_vm._f("formatMoney")(_vm.last2WeekPurchase.reduce((a, b) => a + b.total, 0)),"icon":"ni ni-active-40"}},[_c('template',{slot:"footer"},[_c('span',{staticClass:"text-nowrap"},[_c('b',{staticStyle:{"color":"yellow"}},[_vm._v("Chip: ")]),_vm._v(" $ "+_vm._s(_vm._f("formatMoney")(_vm.last2WeekPurchase.reduce((a, b) => a + b.chipTotal, 0)))+" ")]),_c('span',{staticClass:"text-nowrap"},[_c('b',{staticStyle:{"color":"yellow"}},[_vm._v(" Gold: ")]),_vm._v(" $ "+_vm._s(_vm._f("formatMoney")(_vm.last2WeekPurchase.reduce((a, b) => a + b.goldTotal, 0)))+" ")]),_c('span',{staticClass:"text-nowrap"},[_c('b',{staticStyle:{"color":"yellow"}},[_vm._v(" Multi: ")]),_vm._v(" $ "+_vm._s(_vm._f("formatMoney")(_vm.last2WeekPurchase.reduce((a, b) => a + b.multiTotal, 0)))+" ")]),_c('span',{staticClass:"text-nowrap"},[_c('b',{staticStyle:{"color":"yellow"}},[_vm._v(" Noel: ")]),_vm._v(" $ "+_vm._s(_vm._f("formatMoney")(_vm.last2WeekPurchase.reduce((a, b) => a + b.noelTotal, 0)))+" ")]),_c('br'),_c('span',{staticClass:"text-nowrap"},[_c('b',{staticStyle:{"color":"yellow"}},[_vm._v("Total Order: ")]),_vm._v(" "+_vm._s(_vm.last2WeekPurchase.reduce((a, b) => a + b.totalOrder, 0))+" ")]),_c('span',{staticClass:"text-nowrap"},[_c('b',{staticStyle:{"color":"yellow"}},[_vm._v(" Total Uniq Users: ")]),_vm._v(" "+_vm._s(_vm.last2WeekPurchase.reduce((a, b) => a + b.uniqueUserCount, 0))+" ")]),_c('br'),_vm._l((_vm.last2WeekPurchase.filter(
              (m) => m.total > 0
            )),function(item,index){return _c('span',{key:index,staticClass:"text-nowrap"},[_c('b',{staticStyle:{"color":"yellow"}},[_vm._v(" "+_vm._s(item.name)+":")]),_vm._v(" "+_vm._s(item.total.toFixed(2))+" ")])}),_c('br'),_c('span',{class:_vm.percentageCalc(_vm.last2WeekPurchaseCompare, _vm.last2WeekPurchase) > 0
                ? 'text-success mr-2'
                : 'text-danger mr-2'},[_vm._v(_vm._s(_vm.percentageCalc(_vm.last2WeekPurchaseCompare, _vm.last2WeekPurchase))+"%")]),_c('span',{staticClass:"text-nowrap"},[_vm._v("Since last 15 week $ ("+_vm._s(_vm._f("formatMoney")(_vm.last2WeekPurchaseCompare.reduce((a, b) => a + b.total, 0)))+")")])],2)],2)],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('StatsCard',{staticClass:"mb-4 mb-xl-0",attrs:{"title":"SALES 30 DAYS","type":"gradient-red","sub-title":_vm._f("formatMoney")(_vm.lastMonthPurchase.reduce((a, b) => a + b.total, 0)),"icon":"ni ni-active-40"}},[_c('template',{slot:"footer"},[_c('span',{staticClass:"text-nowrap"},[_c('b',{staticStyle:{"color":"yellow"}},[_vm._v("Chip: ")]),_vm._v(" $ "+_vm._s(_vm._f("formatMoney")(_vm.lastMonthPurchase.reduce((a, b) => a + b.chipTotal, 0)))+" ")]),_c('span',{staticClass:"text-nowrap"},[_c('b',{staticStyle:{"color":"yellow"}},[_vm._v(" Gold: ")]),_vm._v(" $ "+_vm._s(_vm._f("formatMoney")(_vm.lastMonthPurchase.reduce((a, b) => a + b.goldTotal, 0)))+" ")]),_c('span',{staticClass:"text-nowrap"},[_c('b',{staticStyle:{"color":"yellow"}},[_vm._v(" Multi: ")]),_vm._v(" $ "+_vm._s(_vm._f("formatMoney")(_vm.lastMonthPurchase.reduce((a, b) => a + b.multiTotal, 0)))+" ")]),_c('span',{staticClass:"text-nowrap"},[_c('b',{staticStyle:{"color":"yellow"}},[_vm._v(" Noel: ")]),_vm._v(" $ "+_vm._s(_vm._f("formatMoney")(_vm.lastMonthPurchase.reduce((a, b) => a + b.noelTotal, 0)))+" ")]),_c('br'),_c('span',{staticClass:"text-nowrap"},[_c('b',{staticStyle:{"color":"yellow"}},[_vm._v("Total Order: ")]),_vm._v(" "+_vm._s(_vm.lastMonthPurchase.reduce((a, b) => a + b.totalOrder, 0))+" ")]),_c('span',{staticClass:"text-nowrap"},[_c('b',{staticStyle:{"color":"yellow"}},[_vm._v(" Total Uniq Users: ")]),_vm._v(" "+_vm._s(_vm.lastMonthPurchase.reduce((a, b) => a + b.uniqueUserCount, 0))+" ")]),_c('br'),_vm._l((_vm.lastMonthPurchase.filter(
              (m) => m.total > 0
            )),function(item,index){return _c('span',{key:index,staticClass:"text-nowrap"},[_c('b',{staticStyle:{"color":"yellow"}},[_vm._v(" "+_vm._s(item.name)+":")]),_vm._v(" "+_vm._s(item.total.toFixed(2))+" ")])}),_c('br'),_c('span',{class:_vm.percentageCalc(_vm.lastMonthPurchaseCompare, _vm.lastMonthPurchase) > 0
                ? 'text-success mr-2'
                : 'text-danger mr-2'},[_vm._v(_vm._s(_vm.percentageCalc(_vm.lastMonthPurchaseCompare, _vm.lastMonthPurchase))+"%")]),_c('span',{staticClass:"text-nowrap"},[_vm._v("Since last month $ ("+_vm._s(_vm._f("formatMoney")(_vm.lastMonthPurchaseCompare.reduce((a, b) => a + b.total, 0)))+")")])],2)],2)],1)],1),_c('DetailCard',{attrs:{"search":""}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }